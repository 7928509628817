import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import AccordionFrequentlyAsked from '@components/ui/accordions/AccordionFrequentlyAsked'
import SectionHeader from '@components/ui/typography/SectionHeader'

const FrequentlyAskedSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allBusinessPlanWritingFaqJson {
        edges {
          node {
            key
            head
            body
          }
        }
      }
    }
  `)

  return (
    <SectionWrapper sectionBackground={color.blueBackground}>
      <SectionHeader title={intl.formatMessage({ id: 'pricing.frequentlyAsked.title' })} />
      <FrequentlyAskedWrapper>
        <Wrapper>
          {data.allBusinessPlanWritingFaqJson.edges.map((item, index) => {
            return (
              item.node.key === 'left' && (
                <Item key={index}>
                  <AccordionFrequentlyAsked
                    id={index}
                    head={intl.formatMessage({ id: item.node.head })}
                    text={intl.formatMessage({ id: item.node.body })}
                  />
                </Item>
              )
            )
          })}
        </Wrapper>
        <Wrapper>
          {data.allBusinessPlanWritingFaqJson.edges.map((item, index) => {
            return (
              item.node.key === 'right' && (
                <Item key={index}>
                  <AccordionFrequentlyAsked
                    id={index}
                    head={intl.formatMessage({ id: item.node.head })}
                    text={intl.formatMessage({ id: item.node.body })}
                  />
                </Item>
              )
            )
          })}
        </Wrapper>
      </FrequentlyAskedWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(FrequentlyAskedSection)

const FrequentlyAskedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
  }
`

const Wrapper = styled.div`
  width: 100%;
`

const Item = styled.div``
