import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import HeroSection from '@sections/business-plan-writing/HeroSection'
import TrustedBySection from '@sections/home/TrustedBySection'
import RatingSection from '@sections/home/RatingSection'
import FrequentlyAskedSection from '@sections/business-plan-writing/FrequentlyAskedSection'
import ReadySection from '@sections/common/ReadySection'
import { color } from '@configs/utilities'
import BusinessPlanWritingTextImageSection from '@sections/business-plan-writing/BusinessPlanWritingTextImageSection'
const BusinessPlanWriting = ({ intl }) => {
  return (
    <Layout pageName="business-plan-writing">
      <HeroSection />
      <RatingSection
        sectionPaddingSm="10px 0 10px 0"
        sectionPaddingMd="10px 0 10px 0"
        sectionPaddingXl="30px 0 30px 0"
      />
      <TrustedBySection />
      <BusinessPlanWritingTextImageSection />
      <FrequentlyAskedSection />
      <ReadySection
        external
        targetBlank
        href="https://calendar.app.google/zzR3uvj7XjTfA84T7 "
        scrollId="getQuote"
        sectionBackground={color.blueBackground}
        title={intl.formatMessage({ id: 'business-plan-writing.contactSection.title' })}
        description={intl.formatMessage({ id: 'business-plan-writing.contactSection.description' })}
        buttonText="Get a quote"
      />
    </Layout>
  )
}

export default injectIntl(BusinessPlanWriting)
