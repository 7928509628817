import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import { StaticImage } from 'gatsby-plugin-image'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import TextImageComponent from '@components/common/TextImageComponent'
import TestimonialFleckComponent from '@components/common/TestimonialFleckComponent'
import CapterraLogo from '@assets/images/common/testimonialFleckCapterraLogo.svg'
const FeaturesTextImageSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <SectionHeader
        handwriting={intl.formatMessage({ id: 'business-plan-writing.textImageSection.handwriting' })}
        title={intl.formatMessage({ id: 'business-plan-writing.textImageSection.title' })}
        description={intl.formatMessage({ id: 'business-plan-writing.textImageSection.description' })}
        titleMaxWidth="700px"
        descriptionMaxWidth="750px"
      />
      <CardsWrapper>
        <TextImageComponent
          scrollLink
          title={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article01.title' })}
          description={intl.formatMessage({
            id: 'business-plan-writing.textImageSection.articles.article01.description',
          })}
          image={
            <StaticImage
              src="../../assets/images/business-plan-writing/businessPlanReview.webp"
              alt={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article01.title' })}
              placeholder="blurred"
            />
          }
          goToPath="#getQuote"
          linkText="Get a quote"
          color={color.blue}
        />
        <TextImageComponent
          reverse
          scrollLink
          title={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article02.title' })}
          description={intl.formatMessage({
            id: 'business-plan-writing.textImageSection.articles.article02.description',
          })}
          image={
            <StaticImage
              src="../../assets/images/business-plan-writing/writingBusinessPlanFromScratch.webp"
              alt={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article02.title' })}
              placeholder="blurred"
            />
          }
          goToPath="#getQuote"
          linkText="Get a quote"
          color={color.blue}
        />
      </CardsWrapper>

      <TestimonialWrapper>
        <TestimonialFleckComponent
          text={intl.formatMessage({ id: 'miscellaneous.highlyRecommendIdeaBuddy' })}
          signature="Justin P. , Marketing Director"
          logo={
            <img
              src={CapterraLogo}
              alt={intl.formatMessage({ id: 'home.featuresTextImageComponent.business-guide.tabTitle' })}
            />
          }
        />
      </TestimonialWrapper>

      <CardsWrapper>
        <TextImageComponent
          scrollLink
          title={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article03.title' })}
          description={intl.formatMessage({
            id: 'business-plan-writing.textImageSection.articles.article03.description',
          })}
          image={
            <StaticImage
              src="../../assets/images/business-plan-writing/makingOfFinancialForecast.webp"
              alt={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article03.title' })}
              placeholder="blurred"
            />
          }
          goToPath="#getQuote"
          linkText="Get a quote"
          color={color.blue}
        />
        <TextImageComponent
          reverse
          scrollLink
          title={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article04.title' })}
          description={intl.formatMessage({
            id: 'business-plan-writing.textImageSection.articles.article04.description',
          })}
          image={
            <StaticImage
              src="../../assets/images/business-plan-writing/pitchDeckBuilding.webp"
              alt={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article04.title' })}
              placeholder="blurred"
            />
          }
          goToPath="#getQuote"
          linkText="Get a quote"
          color={color.blue}
        />
      </CardsWrapper>
      <CardsWrapper>
        <TextImageComponent
          scrollLink
          title={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article05.title' })}
          description={intl.formatMessage({
            id: 'business-plan-writing.textImageSection.articles.article05.description',
          })}
          image={
            <StaticImage
              src="../../assets/images/business-plan-writing/strategicAdvisory.webp"
              alt={intl.formatMessage({ id: 'business-plan-writing.textImageSection.articles.article05.title' })}
              placeholder="blurred"
            />
          }
          goToPath="#getQuote"
          linkText="Get a quote"
          color={color.blue}
        />
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(FeaturesTextImageSection)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const TestimonialWrapper = styled.div`
  max-width: 1260px;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoint.md}) {
    margin: 70px auto;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    padding: 0 5%;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    padding: 0 90px;
  }
`
