import React from 'react'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import TestimonialFleckBackground from '@assets/images/common/TestimonialFleckBackground.webp'
import TestimonialFleckBackgroundQuotationMarks from '@assets/images/common/TestimonialFleckBackgroundQuotationMarks.webp'

const TestimonialFleckComponent = ({ text, signature, logo }) => {
  return (
    <Wrapper>
      <QuotationMarks />
      <Text>{text}</Text>
      <BottomWrapper>
        <span>{signature}</span>
        <div>{logo}</div>
      </BottomWrapper>
    </Wrapper>
  )
}

export default TestimonialFleckComponent

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  font-size: 16px;
  font-weight: 400;
  background-image: url(${TestimonialFleckBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 64px 20px 46px;

  @media screen and (min-width: ${breakpoint.sm}) {
    padding: 70px 10% 50px;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    justify-content: space-between;
    padding: 70px 15% 50px;
    font-size: 18px;
  }
`

const QuotationMarks = styled.div`
  position: absolute;
  width: 42px;
  height: 36px;
  top: -10px;
  left: 20px;
  background-image: url(${TestimonialFleckBackgroundQuotationMarks});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media screen and (min-width: ${breakpoint.sm}) {
    top: 0;
    left: 10%;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    left: 15%;
  }
`

const Text = styled.p`
  width: 100%;
  color: rgba(8, 30, 74, 0.7);
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
  }

  span {
    color: ${color.blueDark};
  }

  img {
    width: 192px;
  }
`
