import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import { DescriptionDisplay1 } from '@components/ui/typography/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import TagRanked from '@components/common/TagRanked'
import ButtonLink from '@components/ui/buttons/ButtonLink'
const HeroSectionV5 = ({ intl }) => {
  return (
    <SectionWrapper>
      <Wrapper>
        <Text>
          <TagRanked text={intl.formatMessage({ id: 'miscellaneous.ratedOne' })} margin="0 0 -10px 0" />
          <div className="headingWrapper">
            <HeadingH1>{intl.formatMessage({ id: 'business-plan-writing.heroSection.title' })}</HeadingH1>
          </div>
          <div className="descriptionWrapper">
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'business-plan-writing.heroSection.description' })}
            </DescriptionDisplay1>
          </div>
          <div className="buttonLinkWrapper">
            <div className="buttons">
              <ButtonLink
                external
                targetBlank
                href="https://calendar.app.google/zzR3uvj7XjTfA84T7"
                buttonText="Get a quote"
                buttonBackground={color.blue}
                buttonColor={color.white}
              />
            </div>
          </div>
        </Text>
        <StaticImage src="../../assets/images/business-plan-writing/hero.webp" width={654} alt="Hero image" />
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(HeroSectionV5)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoint.sm}) {
    align-items: center;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
`

const HeadingH1 = styled.h1`
  font-size: 38px;
  font-weight: 700;
  color: ${color.font};

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 48px;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  @media screen and (min-width: ${breakpoint.xl}) {
    align-items: flex-start;
    max-width: 45%;
  }

  .headingWrapper {
    text-align: center;

    @media screen and (min-width: ${breakpoint.xl}) {
      text-align: left;
    }
  }

  .descriptionWrapper {
    max-width: 450px;
    text-align: center;

    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 550px;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      text-align: left;
    }
  }

  .buttonLinkWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;
    margin-bottom: 40px;

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 12px;

      @media screen and (min-width: ${breakpoint.xl}) {
        padding-bottom: 0;
        flex-direction: row;
        column-gap: 12px;
        justify-content: flex-start;
      }
    }

    p {
      opacity: 0.4;
      font-size: 12px;
    }
  }
`
